import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { Popover, Menu, Transition, Disclosure } from "@headlessui/react";
import { useRouter } from "next/router";
import { Trans } from "@lingui/macro";
import Link from "next/link";
import Image from "next/image";
import {
  MenuIcon,
  UserIcon,
  ChevronUpIcon,
  DocumentTextIcon,
  ExternalLinkIcon,
  UserCircleIcon,
} from "@heroicons/react/outline";
import { useGetLeadDetailsQuery } from "@/app/services/leads";
import { getLocale } from "@/utils/locale";
import { handleActionModal } from "@/features/navLocaleAlert/NavLocaleAlertSlice";
import { useDispatch, useSelector } from "react-redux";
import classNames from "@/utils/classNames";
import countries from "@/utils/countriesFormat";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { uuidCart } from "@/app/slices/appSlice";
import SearchInput from "../SearchInput";
import NavLease from "./NavLease";
import NavPurchase from "./NavPurchase";
import NavLeaseMobile from "./NavLeaseMobile";
import { setUser } from "@/features/authSlice";
import { useGetMeQuery } from "@/app/services/user";
import { getUserToken, removeUserCountry, removeUserToken } from "@/utils/auth";
import ValidateAlert from "@/components/User/ValidateAlert";
import LocaleAlert from "@/components/commons/LocaleAlert";

export default function Nav() {
  const [token, setToken] = useState(null);
  const [validatedUser, setValidatedUser] = useState(false);
  const { data: user } = useGetMeQuery(null, { skip: !token });
  const { locale, push } = useRouter();
  const lcl = getLocale();
  const dispatch = useDispatch();
  const [isLocaleAlertOpen, setIsLocaleAlertOpen] = useState(false);

  useEffect(() => {
    setToken(getUserToken());
  }, []);

  const uuid = useSelector(uuidCart);

  let { data: itemsCart } = useGetLeadDetailsQuery(
    {
      locale: lcl,
      uuid: uuid,
    },
    { skip: uuid === null }
  );

  if (uuid == null) {
    itemsCart = [];
  }

  const handleChangeLocale = (locale: string) => {
    if (user && user.country_id !== locale.slice) {
      dispatch(
        handleActionModal({
          isModalOpen: true,
          locale: locale,
          reason: "user_country",
        })
      );
    } else if (uuid !== null) {
      dispatch(
        handleActionModal({ isModalOpen: true, locale: locale, reason: "cart" })
      );
    } else {
      push("/", `/${locale}`, { locale: locale });
    }
  };

  const handleLogout = () => {
    dispatch(setUser(null));
    removeUserToken();
    removeUserCountry();

    window.location.href = "/";
  };

  const currentCountry = useMemo(() => {
    return countries.find((e) => e.code == locale);
  }, [locale]);

  const buttonRef = useRef(null);

  const imageApple =
    locale === "pt-BR"
      ? "https://apple-resources.s3.amazonaws.com/media-badges/download-on-the-app-store/black/pt-br.svg"
      : "https://apple-resources.s3.amazonaws.com/media-badges/download-on-the-app-store/black/es-mx.svg";

  const imageAndroid =
    locale === "pt-BR"
      ? "https://play.google.com/intl/en_us/badges/static/images/badges/pt_badge_web_generic.png"
      : "https://play.google.com/intl/en_us/badges/static/images/badges/es_badge_web_generic.png";

  const blogsByLocale = {
    "es-CL": "https://blog.rendalomaq.com/",
    "pt-BR": "https://blog-br.rendalomaq.com/",
    "es-MX": "https://blog-mx.rendalomaq.com/",
    "/": "https://blog.rendalomaq.com/",
  };

  const blogUrl = blogsByLocale[locale];

  const goToAnchor = async (route, anchor) => {
    await push(route);
    let elm = document.getElementById(anchor);
    elm && elm.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleUserLocale = () => {
    if (locale.slice(-2) !== user?.user?.country_id) {
      setIsLocaleAlertOpen(true);
    }
  };

  useEffect(() => {
    if (user && user?.user?.is_email_validated === true) setValidatedUser(true);
    if (user) handleUserLocale();
  }, [user]);

  return (
    <div className="w-full z-40 sticky top-0 bg-white">
      <Popover className="relative" as="nav">
        <div
          className="absolute inset-0 shadow pointer-events-none bg-white"
          aria-hidden="true"
        />
        <div className="relative z-20">
          <div className="max-w-[90%] flex sm:justify-between items-center pb-5 sm:pb-4 sm:px-4 2xl:px-0 sm:max-w-7xl mx-auto">
            <div className="w-5/6 sm:w-3/12 mt-2">
              <a href="#" className="flex items-start">
                <span className="sr-only">RendaloMaq</span>
                <Link href={`/${locale}`} passHref>
                  <span>
                    <span className="hidden sm:block">
                      <Image
                        width={270}
                        height={20}
                        src="/colorful.svg"
                        alt="RendaloMaq logo"
                        priority
                      />
                    </span>
                    <span className="sm:hidden flex items-center">
                      <Image
                        width={240}
                        height={20}
                        objectFit={"contain"}
                        src="/colorful.svg"
                        alt="RendaloMaq logo"
                        priority
                      />
                    </span>
                  </span>
                </Link>
              </a>
            </div>
            <div className="hidden lg:flex relative z-0 flex-1 items-center justify-center px-2 w-6/12 lg:inset-0 ">
              <SearchInput />
            </div>
            <div className="flex items-center w-1/2 lg:w-3/12 justify-end">
              <Menu as="div" className="mr-3 relative">
                <div>
                  <Menu.Button className="max-w-xs bg-white hidden lg:flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-safety-orange">
                    <span className="sr-only">Open user menu</span>
                    <div
                      className="rounded-full w-[24px] h-[24px]"
                      style={{
                        backgroundImage: `url(/i18n/${locale}.jpg)`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></div>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    className="origin-top-right absolute right-0 mt-2 w-32 z-40 rounded-md shadow-lg py-1 bg-white ring-1 
                  ring-black ring-opacity-5 focus:outline-none"
                  >
                    {countries.map((country) => (
                      <Menu.Item key={country.code}>
                        <div
                          className="grid grid-cols-2 p-2 hover:cursor-pointer hover:bg-alice-blue-150"
                          onClick={() => handleChangeLocale(country.code)}
                        >
                          <div
                            className="rounded-full w-6 h-6 ml-2"
                            style={{
                              backgroundImage: `url(/i18n/${country.code}.jpg)`,
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                            }}
                          ></div>
                          <div>{country.label}</div>
                        </div>
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
              {/* <Popover className="relative h-[24px] w-[24px]">
                {({ close }) => (
                  <>
                    <Popover.Button className="h-[24px] w-[24px] text-base font-medium text-gummetal hover:text-safety-orange mr-2">
                      <span className="sr-only">Open user menu</span>
                      <UserIcon className="h-[24px]" />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute -right-14 sm:right-0 z-10 mt-3 w-screen max-w-sm transform px-2 sm:px-0">
                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                          <div className="relative grid gap-4 bg-white p-2.5">
                            <div className="flex items-center w-full">
                              {user ? (
                                <div className="relative h-10 w-10 rounded-full overflow-hidden mr-2">
                                  <Image
                                    src={user.user.avatar}
                                    layout="fill"
                                    alt="avatar"
                                  />
                                </div>
                              ) : (
                                <UserCircleIcon className="h-[44px] mr-2" />
                              )}
                              <div>
                                <p>
                                  <strong>
                                    <Trans>Welcome</Trans>,
                                  </strong>
                                </p>
                                <p>
                                  {user?.name ? (
                                    user?.name
                                  ) : (
                                    <Trans>Log in as client</Trans>
                                  )}
                                </p>
                              </div>
                            </div>

                            {user && !validatedUser ? (
                              <ValidateAlert small={true} />
                            ) : (
                              <></>
                            )}

                            {user ? (
                              <div className="">
                                <button
                                  onClick={() => {
                                    push(`/${locale}/user/leads`);
                                    close();
                                  }}
                                  className="hover:bg-alice-blue px-2 py-3 rounded-md text-left rounded-md w-full"
                                >
                                  <Trans>My requests</Trans>
                                </button>
                                <button
                                  onClick={() => {
                                    push(`/${locale}/user/addresses`);
                                    close();
                                  }}
                                  className="hover:bg-alice-blue px-2 py-3 rounded-md text-left rounded-md w-full"
                                >
                                  <Trans>My addresses</Trans>
                                </button>
                                <button
                                  onClick={() => {
                                    push(`/${locale}/user/profile`);
                                    close();
                                  }}
                                  className="hover:bg-alice-blue px-2 py-3 rounded-md text-left rounded-md w-full"
                                >
                                  <Trans>My profile</Trans>
                                </button>
                                <button
                                  onClick={handleLogout}
                                  className="btn-black rounded-md w-full mt-4"
                                >
                                  <Trans>Log out</Trans>
                                </button>
                              </div>
                            ) : (
                              <div className="flex items-center gap-4">
                                <button
                                  onClick={() => {
                                    push(`/${locale}/auth/login`);
                                    close();
                                  }}
                                  className="btn-primary rounded-md w-1/2"
                                >
                                  <Trans>Log in</Trans>
                                </button>
                                <button
                                  onClick={() => {
                                    push(`/${locale}/auth/signup`);
                                    close();
                                  }}
                                  className="btn-black rounded-md w-1/2"
                                >
                                  <Trans>Create account</Trans>
                                </button>
                              </div>
                            )}
                          </div>
                          <div className="bg-alice-blue p-2.5">
                            <p className="text-sm mb-2">
                              <Trans>Providers</Trans>
                            </p>
                            <hr />
                            <ul role="list" className="mt-5 space-y-6">
                              <li className="flow-root">
                                <a
                                  href="https://suppliers.rendalomaq.com"
                                  target="_blank"
                                  rel="noreferrer"
                                  className="-m-3 flex items-center justify-between rounded-md p-3 text-base font-medium text-gray-900 transition duration-150 ease-in-out hover:bg-gray-50"
                                >
                                  <span>
                                    <Trans>Log in as provider</Trans>
                                  </span>
                                  <ExternalLinkIcon
                                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </a>
                              </li>
                              <li
                                className="flow-root hover:cursor-pointer"
                                onClick={() => {
                                  goToAnchor(
                                    `/${locale}/arrendar-mi-maquinaria`,
                                    "form"
                                  );
                                  close();
                                }}
                              >
                                <a className="-m-3 flex items-center justify-between rounded-md p-3 text-base font-medium text-gray-900 transition duration-150 ease-in-out hover:bg-gray-50">
                                  <span>
                                    <Trans>I want to be a provider!</Trans>
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
                              </Popover>*/}
            </div>
            <div className="-my-2 lg:hidden">
              <Popover.Button
                ref={buttonRef}
                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gummetal hover:text-gummetal hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-safety-orange"
              >
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute z-30 top-18 inset-x-0 p-2 transition transform origin-top-right lg:hidden"
          >
            <div className="rounded-lg overflow-hidden bg-white ring-1 ring-black shadow-xl ring-opacity-5">
              <div className="">
                <div className="bg-alice-blue w-full px-4 py-2">
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full h-11 items-center py-2 text-left font-medium text-gray-900 focus:outline-none focus-visible:ring rounded-xl ">
                          <div
                            className="rounded-full w-6 h-6"
                            style={{
                              backgroundImage: `url(/i18n/${locale}.jpg)`,
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                            }}
                          ></div>
                          <span className="grow ml-2">
                            {currentCountry.label}
                          </span>
                          <ChevronUpIcon
                            className={`${
                              !open ? "rotate-180 transform" : ""
                            } h-6 w-6 text-gummetal`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="text-gummetal">
                          <ul className="flex flex-col mt-2 gap-2 justify-start items-start text-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-safety-orange">
                            {countries
                              .filter((e) => e.code !== locale)
                              .map((country) => (
                                <li key={country.code} className="w-full">
                                  <div
                                    className="flex gap-3 items-center h-11 hover:cursor-pointer bg-[#d9e4ea] px-4 rounded-lg"
                                    onClick={() =>
                                      handleChangeLocale(country.code)
                                    }
                                  >
                                    <div
                                      className="rounded-full w-6 h-6"
                                      style={{
                                        backgroundImage: `url(/i18n/${country.code}.jpg)`,
                                        backgroundPosition: "center",
                                        backgroundSize: "cover",
                                        backgroundRepeat: "no-repeat",
                                      }}
                                    ></div>
                                    <div>{country.label}</div>
                                  </div>
                                </li>
                              ))}
                          </ul>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </div>
                <div className="grid grid-row-2 gap-2 pt-5 pb-6 px-2 sm:pb-8">
                  <button onClick={() => buttonRef.current?.click()}>
                    <Link href={`/${locale}`} passHref>
                      <a className="h-11 items-center text-base flex gap-2 font-medium text-gray-900 px-2 hover:text-gray-700">
                        <Trans>Home</Trans>
                      </a>
                    </Link>
                  </button>
                  <Disclosure>
                    {({ open }) => (
                      <div
                        className={
                          open
                            ? "bg-alice-blue px-2 rounded-lg pb-2"
                            : "px-2 rounded-lg pb-2"
                        }
                      >
                        <Disclosure.Button className="flex w-full h-11 items-center py-2 text-left font-medium text-gray-900 focus:outline-none focus-visible:ring rounded-xl ">
                          <span className="grow">
                            {locale !== "pt-BR" ? (
                              <Trans>Lease</Trans>
                            ) : (
                              <Trans>Catalog</Trans>
                            )}
                          </span>
                          <ChevronUpIcon
                            className={`${
                              !open ? "rotate-180 transform" : ""
                            } h-6 w-6 text-gummetal`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="text-gummetal">
                          <ul className="flex flex-col mt-2 gap-2 justify-start items-start text-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-safety-orange">
                            {open && (
                              <NavLeaseMobile
                                handleClick={() => buttonRef.current?.click()}
                              />
                            )}
                            <li
                              onClick={() => buttonRef.current?.click()}
                              className="w-full"
                            >
                              <Link href={`/${locale}/catalog/all`}>
                                <div className="flex gap-3 items-center h-11 hover:cursor-pointer bg-[#d9e4ea] px-4 rounded-lg cursor-pointer">
                                  <div>
                                    <Trans>See all catalog</Trans>
                                  </div>
                                </div>
                              </Link>
                            </li>
                          </ul>
                        </Disclosure.Panel>
                      </div>
                    )}
                  </Disclosure>
                  {locale !== "pt-BR" && (
                    <button onClick={() => buttonRef.current?.click()}>
                      <Link href={`/${locale}/buy`} passHref>
                        <a className="h-11 items-center text-base flex gap-2 font-medium text-gray-900 px-2 hover:text-gray-700">
                          <Trans>nav_purchase_button</Trans>
                        </a>
                      </Link>
                    </button>
                  )}

                  <a
                    href={`/${locale}/arrendar-mi-maquinaria`}
                    target="_self"
                    rel="noreferrer"
                    className="flex px-2 gap-2 items-center h-11 text-base font-medium text-gray-900 hover:text-gray-700"
                  >
                    <Trans>Providers</Trans>
                  </a>
                  <a
                    href={blogUrl}
                    target="_blank"
                    rel="noreferrer"
                    className="flex px-2 gap-2 items-center h-11 text-base font-medium text-gray-900 hover:text-gray-700"
                  >
                    <Trans>Blog</Trans>
                  </a>
                </div>
                <div className="bg-alice-blue py-4 pt-5 pb-6 px-2 sm:pb-8">
                  <p className="text-center">
                    <Trans>Download our app for providers</Trans>
                  </p>
                  <div className="flex justify-between items-center">
                    <Image src={imageApple} width={120} height={40}></Image>
                    <Image src={imageAndroid} width={146} height={55}></Image>
                  </div>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
      <header className="lg:flex justify-between items-center sm:px-4 2xl:px-0 bg-gummetal w-full">
        <div className="lg:hidden relative z-0 flex flex-1 items-center justify-center px-4 sm:px-0 lg:absolute lg:inset-0 pt-2">
          <SearchInput />
        </div>
        <Popover className="relative z-0 max-w-[90%] w-full flex justify-between items-center sm:max-w-7xl mx-auto">
          {({ open }) => (
            <>
              <nav
                className="max-w-[90%] w-full flex justify-between items-center sm:max-w-7xl mx-auto"
                aria-label="Top"
              >
                <div className="sm:max-w-1/2 hidden lg:flex md:items-center md:justify-between">
                  <Popover.Group as="nav" className="flex space-x-2">
                    <Popover>
                      {({ open }) => (
                        <>
                          <Popover.Button
                            className={classNames(
                              open
                                ? "bg-alice-blue text-gummetal"
                                : "text-alice-blue",
                              "rounded-md py-1 px-2 outline-0 focus:ring-0"
                            )}
                          >
                            <span className="text-base font-bold">
                              {locale !== "pt-BR" ? (
                                <Trans>Lease</Trans>
                              ) : (
                                <Trans>Catalog</Trans>
                              )}
                            </span>
                            <ChevronDownIcon
                              className={classNames(
                                open ? "rotate-180 transform" : "",
                                "ml-2 h-5 w-5 inline-block"
                              )}
                              aria-hidden="true"
                            />
                          </Popover.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 -translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 -translate-y-1"
                          >
                            <Popover.Panel className="absolute top-[100%] inset-x-0 z-10 transform shadow-lg">
                              {({ close }) =>
                                open && <NavLease handleClose={close} />
                              }
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>

                    {locale !== "pt-BR" && (
                      <Popover>
                        {({ open }) => (
                          <>
                            <Popover.Button
                              className={classNames(
                                open
                                  ? "bg-alice-blue text-gummetal"
                                  : "text-alice-blue",
                                "rounded-md py-1 px-2 outline-0 focus:ring-0"
                              )}
                            >
                              <span className="text-base font-bold">
                                <Trans>nav_purchase_button</Trans>
                              </span>
                              <ChevronDownIcon
                                className={classNames(
                                  open ? "rotate-180 transform" : "",
                                  "ml-2 h-5 w-5 inline-block"
                                )}
                                aria-hidden="true"
                              />
                            </Popover.Button>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="opacity-0 -translate-y-1"
                              enterTo="opacity-100 translate-y-0"
                              leave="transition ease-in duration-150"
                              leaveFrom="opacity-100 translate-y-0"
                              leaveTo="opacity-0 -translate-y-1"
                            >
                              <Popover.Panel className="absolute top-[100%] inset-x-0 z-10 transform shadow-lg">
                                {({ close }) => (
                                  <NavPurchase handleClose={close} />
                                )}
                              </Popover.Panel>
                            </Transition>
                          </>
                        )}
                      </Popover>
                    )}
                    {locale === "pt-BR" && (
                      <Link href="/pt-BR/arrendar-mi-maquinaria" passHref>
                        <a
                          href="#"
                          className="text-base font-bold text-alice-blue capitalize rounded-md py-1 px-2"
                        >
                          <Trans>Providers</Trans>
                        </a>
                      </Link>
                    )}

                    {locale !== "pt-BR" && (
                      <Popover>
                        {({ open }) => (
                          <>
                            <Popover.Button
                              className={classNames(
                                open
                                  ? "bg-alice-blue text-gummetal"
                                  : "text-alice-blue",
                                "rounded-md py-1 px-2 focus:ring-0 outline-0"
                              )}
                            >
                              <span className="text-base font-bold">
                                <Trans>Solutions</Trans>
                              </span>
                              <ChevronDownIcon
                                className={classNames(
                                  open ? "rotate-180 transform" : "",
                                  "ml-2 h-5 w-5 inline-block"
                                )}
                                aria-hidden="true"
                              />
                            </Popover.Button>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="opacity-0 -translate-y-1"
                              enterTo="opacity-100 translate-y-0"
                              leave="transition ease-in duration-150"
                              leaveFrom="opacity-100 translate-y-0"
                              leaveTo="opacity-0 -translate-y-1"
                            >
                              <Popover.Panel className="absolute top-[100%] inset-x-0 z-10 transform shadow-lg">
                                {({ close }) => (
                                  <>
                                    <div
                                      className="absolute inset-0 flex"
                                      aria-hidden="true"
                                    >
                                      <div className="w-full bg-white" />
                                    </div>
                                    <div className="relative mx-auto grid max-w-7xl grid-cols-1">
                                      <nav
                                        className="grid gap-y-10 bg-white px-4 py-8 sm:grid-cols-12 sm:gap-x-8 sm:py-12 sm:px-6 lg:px-8 xl:pr-12"
                                        aria-labelledby="catalog-heading"
                                      >
                                        <h2
                                          id="catalog-heading"
                                          className="sr-only"
                                        >
                                          Solutions menu
                                        </h2>
                                        <div className="flex gap-x-4 col-span-6">
                                          <div
                                            onClick={() => {
                                              push(
                                                `/${locale}/arriendo-maquinaria`
                                              );
                                              close();
                                            }}
                                            className="w-1/2 transition-all hover:bg-alice-blue px-2 pt-2 rounded-lg hover:cursor-pointer flex flex-col justify-between pb-2"
                                          >
                                            <Image
                                              className="object-cover object-center rounded-md"
                                              width={300}
                                              height={300}
                                              alt="Cliente"
                                              src="/Nav/clients.jpg"
                                            />
                                          </div>
                                          <ul className="w-1/2">
                                            <div className="flex items-center">
                                              <span className="material-icons-outlined px-2">
                                                people_alt
                                              </span>
                                              <h3 className="text-lg font-semibold py-1">
                                                <Trans>Clients</Trans>
                                              </h3>
                                            </div>

                                            <p className="ml-2 mb-4 text-gray-500">
                                              <Trans>
                                                We solve the lease and purchase
                                                of machinery for you
                                              </Trans>
                                            </p>
                                            <li
                                              onClick={() => {
                                                goToAnchor(
                                                  `/${locale}/arriendo-maquinaria`,
                                                  "hdwdi"
                                                );
                                                close();
                                              }}
                                              className="px-2 py-2 mb-1 hover:bg-alice-blue rounded-md hover:cursor-pointer"
                                            >
                                              <Trans>How do we do it?</Trans>
                                            </li>
                                            <li
                                              onClick={() => {
                                                goToAnchor(
                                                  `/${locale}/arriendo-maquinaria`,
                                                  "benefits"
                                                );
                                                close();
                                              }}
                                              className="px-2 py-2 mb-1 hover:bg-alice-blue rounded-md hover:cursor-pointer"
                                            >
                                              <Trans>Benefits</Trans>
                                            </li>
                                          </ul>
                                        </div>
                                        <div className="flex gap-x-4 col-span-6">
                                          <div
                                            onClick={() => {
                                              push(
                                                `/${locale}/arrendar-mi-maquinaria`
                                              );
                                              close();
                                            }}
                                            className="w-1/2 transition-all hover:bg-alice-blue px-2 pt-2 rounded-lg hover:cursor-pointer"
                                          >
                                            <Image
                                              width={300}
                                              height={300}
                                              alt="Cliente"
                                              src="/Nav/providers.jpg"
                                            />
                                          </div>
                                          <ul className="w-1/2 space-y-2">
                                            <div className="flex items-center">
                                              <span className="material-icons-outlined px-2">
                                                build
                                              </span>
                                              <h3 className="text-lg font-semibold px-2 py-1">
                                                <Trans>Providers</Trans>
                                              </h3>
                                            </div>
                                            <p className="ml-2 mb-4 text-gray-500">
                                              <Trans>
                                                Rent or sell with RendaloMaq and
                                                access great benefits
                                              </Trans>
                                            </p>
                                            <li
                                              onClick={() => {
                                                goToAnchor(
                                                  `/${locale}/arrendar-mi-maquinaria`,
                                                  "benefits"
                                                );
                                                close();
                                              }}
                                              className="px-2 py-2 mb-1 transition-all hover:bg-alice-blue rounded-md hover:cursor-pointer"
                                            >
                                              <Trans>Benefits</Trans>
                                            </li>
                                            <li
                                              onClick={() => {
                                                goToAnchor(
                                                  `/${locale}/arrendar-mi-maquinaria`,
                                                  "hdiw"
                                                );
                                                close();
                                              }}
                                              className="px-2 py-2 mb-1 hover:bg-alice-blue rounded-md hover:cursor-pointer"
                                            >
                                              <Trans>How does it work?</Trans>
                                            </li>
                                            <li
                                              onClick={() => {
                                                goToAnchor(
                                                  `/${locale}/arrendar-mi-maquinaria`,
                                                  "faq"
                                                );
                                                close();
                                              }}
                                              className="px-2 py-2 mb-1 hover:bg-alice-blue rounded-md hover:cursor-pointer"
                                            >
                                              <Trans>
                                                Frequently asked questions
                                              </Trans>
                                            </li>
                                            <li
                                              onClick={() => {
                                                goToAnchor(
                                                  `/${locale}/arrendar-mi-maquinaria`,
                                                  "form"
                                                );
                                                close();
                                              }}
                                              className="px-2 py-2 mb-1 bg-gradient bg-gradient-hover rounded-md hover:cursor-pointer text-white"
                                            >
                                              <Trans>
                                                Sign up as a provider now!
                                              </Trans>
                                            </li>
                                          </ul>
                                        </div>
                                      </nav>
                                    </div>
                                  </>
                                )}
                              </Popover.Panel>
                            </Transition>
                          </>
                        )}
                      </Popover>
                    )}
                    <a
                      href={blogUrl}
                      target="_blank"
                      rel="noreferrer"
                      className="text-base rounded-md py-1 px-2 font-bold text-alice-blue"
                    >
                      <Trans>Blog</Trans>
                    </a>
                  </Popover.Group>
                </div>
                <div className="sm:max-w-1/2 py-2 flex gap-4 items-center text-white justify-between">
                  <div className="hidden mx-auto lg:text-left lg:flex sm:items-center sm:justify-center sm:text-center sm:text-xs space-x-4 font-semibold">
                    <div className="truncate">
                      <Trans>Are you a provider? Rent or sell with us</Trans>
                    </div>
                    <Link href={`/${locale}/arrendar-mi-maquinaria`} passHref>
                      <a
                        href="#"
                        className="min-w-fit inline-flex items-center ml-2 px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-safety-orange to-fiery-rose hover:from-fiery-rose hover:to-safety-orange sm:text-xs focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-safety-orange"
                      >
                        <Trans>Enroll now</Trans>
                      </a>
                    </Link>
                  </div>
                </div>
              </nav>
            </>
          )}
        </Popover>
      </header>
      <LocaleAlert
        open={isLocaleAlertOpen}
        country_id={user?.user?.country_id}
      />
    </div>
  );
}
